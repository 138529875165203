.single_product_list{
    padding: 200px 0 100px;
    @media #{$tab}{
        padding: 100px 0 0;
    }
    @media #{$medium_device}{
        padding: 100px 0 100px;
    }
    .single_product_img{
        position: relative;
        margin: 35px 0 0 35px;
        @media #{$tab}{
            max-width: 204px;
        }
        .product_overlay{
            position: absolute;
            left: -35px;
            top: -35px;
            z-index: -1;
        }
    }
    .single_product_content{
        h5{
            font-weight: 500;
            font-size: 20px;
            color: #FFCCF2;
            @media #{$tab}{
                font-size: 16px;
            }
        }
        h2{
            font-size: 40px;
            line-height: 1.5;
            font-weight: 500;
            margin: 10px 0 50px;
            a{
                color: $heading_color;
                &:hover{
                    color: $text-color;
                }
            }
            @media #{$tab}{
                font-size: 25px;
                margin: 10px 0 30px;
            }
            @media #{$medium_device}{
                font-size: 30px;
                margin: 10px 0 30px;
            }
        }
        @media #{$tab}{
            margin-top: 80px;
            .btn_3{
                padding: 13px 29px;
                
            }
        }
    }
    .single_product_iner{
        margin-bottom: 100px;
        @media #{$tab}{
            margin-bottom: 60px;
        }
        &:nth-child(2n-2){
            .row{
                flex-direction: row-reverse;
                margin-top: 140px;
                @media #{$tab}{
                    margin-bottom: 70px;
                    margin-top: 100px;
                }
                
                .single_product_content{
                    @media #{$tab}{
                        margin-top: 40px;
                        .btn_3{
                            padding: 13px 29px;
                            
                        }
                    }
                }
                .single_product_img{
                    position: relative;
                    margin: 0 35px 35px 0;
                    .product_overlay{
                        position: absolute;
                        left: auto;
                        right: -35px;
                        top: -35px;
                        z-index: -1;
                    }
                }
            }
        }
    }
}
.single_product_breadcrumb{
    height: 460px !important;
    @media #{$tab}{
        height: 250px !important;
    }
}
.product_image_area{
    margin-top: -360px;
    @media #{$tab}{
        margin-top: -150px !important;
    }
    .owl-nav{
        button.owl-prev, button.owl-next{
            width: 40px;
            height: 40px;
            font-size: 14px;
            line-height: 40px;
            border-radius: 50%;
            border: 1px solid #E4D3DF;
            color: $white;
            background-color: #E4D3DF;
            position: absolute;
            left: 30px;
            top: 54%;
            @include transform_time(.5s);
            &:hover{
                background-color: $text-color;
                color: $white;
            }
        }
        button.owl-next{
            text-indent: 2px;
            left: auto;
            right: 30px
        }
    }
    .single_product_text{
        margin: 100px 0 200px;
        @media #{$tab}{
            margin: 40px 0 70px;
        }
        @media #{$medium_device}{
            margin: 50px 0 80px;
        }
        h3{
            font-size: 40px;
            @media #{$tab}{
                font-size: 25px;
                br{
                    display: none;
                }
            }
        }
        p{
            margin-top: 50px;
            color: #707070;
            @media #{$tab}{
                margin-top:20px;
            }
            @media #{$medium_device}{
                margin-top:20px;
            }
        }
    }
}
.card_area{
    margin-top: 60px;
    .product_count_area{
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            margin-top: 0 !important;
            color: $text-color;
        }
    }
    .product_count{
        border: 1px solid $text-color;
        margin: 0 35px;
        @media #{$tab}{
            margin: 0 15px;
        }
        .product_count_item{
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            display: inline-block;
            @media #{$tab}{
                width: 45px;
            }
        }
        input{
            background-color: $text-color;
            color: $white;
            border: 1px solid $text-color;
            color: $white;
        }
    }
    .add_to_cart{
        margin-top: 60px;
    }
}
input:focus {
    outline-offset: 0;
}
